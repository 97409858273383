import { useContext } from "react";

import HeroImg from "../../assets/mainItems/heroImg.png";
import MainLocations from "../../components/LocationComponents/MainLocations";
import "./Catering.css";

import WebplaceContext from "../../store/webplace-context";
import Navbar from "../../components/ui/navbar/Navbar";

const Catering = () => {

  const locationsCtx = useContext(WebplaceContext).locationInformation.locations;

  return (
    <div aria-label="locations-view">
      <a href="#main-catering-information" className="skip-to-main">Skip to main content</a>
      <Navbar />
      <div className="spacer"></div>
      <div className="catering-main-wrapper" id="main-catering-information">
        <img src={HeroImg} alt="main show food" className="image-style" />
        <div className="catering-title-wrapper"><div className="catering-title" role="heading" aria-level="1">Catering</div></div>
        {locationsCtx.map((location, index) => {
          return (
            <div key={location.id}>
              <MainLocations location={location} index={index} catering={true} />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Catering;
